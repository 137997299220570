/* .mapContainer {
  height: 80vh;
  width: 95%;
  border-radius: 10px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 1 1 1 0, 5;
  margin: auto;
}

@media only screen and (max-device-width: 768px) {
  .mapContainer {
    width: 100%;
    margin: 0;
  }
} */

.marker {
  color: rgb(31, 31, 31);
  /* text-shadow: 1px 1px black; */
  -webkit-text-stroke: 1px rgb(31, 31, 31);
  /* position: absolute; */
  /* bottom: 0px;
  left: 0px;
  background-color: rgb(58, 219, 26); */
}

.markerLabel {
  z-index: 10000;
}

.infoBox {
  background-color: rgb(180, 209, 18);

  width: 50px;
  margin-left: -25px;
  margin-top: -10px;
  text-align: center;
  border-radius: 3px;
  color: rgb(31, 31, 31);
  font-size: 10px;
  font-weight: 100;
}

.infoBox::before {
  content: "";
  border-left: rgb(31, 31, 31) 3px solid;
  display: inline-block;
  /* background-color: red; */
  width: 3px;
  height: 13px;
  position: absolute;
  left: 0;
}

.shadowBox {
  box-shadow: inset 1px -1px 1px 1px rgb(6 182 212),
    inset 0px -1px 3px 2px #ffffff;
}
.today {
  border-right: rgba(0, 0, 0, 0.3) solid 3px;
  border-left: rgba(0, 0, 0, 0.3) solid 3px;
}

button:hover:active {
  box-shadow: inset 1px -1px 0px 0px rgb(6 182 212),
    inset 0px -1px 3px 2px #ffffff;
}

button:disabled {
  opacity: 0.4;
}

.contextMenu {
  padding: 0 5px;
  border-radius: 3px;
}

.contextMenu:hover {
  background-color: lightblue;
  cursor: pointer;
  color: white;
}
