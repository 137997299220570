.spinner {
  margin: auto;
  padding-top: 10rem;
  width: 50%;
  width: 40px;
  height: 40px;
}
.spinner:after {
  margin: auto;
  width: 50%;
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  margin: 8px;
  border-radius: 50%;
  border: 4px solid teal;
  border-color: #05385a transparent rgb(0, 162, 255) transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
